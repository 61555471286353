import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import ImagePicker from "../../components/ImagePicker";
import EventPageStore from "./store";
import { observer } from "mobx-react";
import { updateEventBrandingImages } from "../../api";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { successToast } from "../../toastAlerts";

interface BrandImagesPickersProps {
    mainImageUrl?: string;
    backgroundImageUrl?: string;
}
const BrandImagesPickers: React.FC<BrandImagesPickersProps> = observer(({ backgroundImageUrl, mainImageUrl }) => {
    const [isUpdatingImages, setIsUpdatingImages] = useState(false);

    const handleBackgroundImageSelected = (file: File | null) => {
        EventPageStore.setBackgroundImage(file);
    }

    const handleBrandImageSelected = (file: File | null) => {
        EventPageStore.setMainImage(file);
    }

    const handleUpdateImages = async () => {
        const backgroundImage = EventPageStore.backgroundImage;
        const mainImage = EventPageStore.mainImage;

        if (!mainImage && !backgroundImage) {
            return;
        }

        setIsUpdatingImages(true);
        await updateEventBrandingImages(EventPageStore.eventId, mainImage, backgroundImage);
        setIsUpdatingImages(false);
        successToast('התמונות עודכנו בהצלחה');
    }

    return (
        <Grid container spacing={5} mt={3}>
            <Grid item xs={6}>
                <Typography variant="h6">בחר תמונת רקע</Typography>
                <ImagePicker
                    onImageSelected={handleBackgroundImageSelected}
                    label="לחץ לבחירה תמונת רקע"
                    name="backgroundImage"
                    selectedImageUrl={backgroundImageUrl} />
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h6">בחר תמונת עיגול מיתוג</Typography>
                <ImagePicker
                    onImageSelected={handleBrandImageSelected}
                    label="לחץ לבחירת תמונת עיגול מיתוג"
                    name="mainImage"
                    selectedImageUrl={mainImageUrl}
                />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton variant="contained" color="primary" onClick={handleUpdateImages} loading={isUpdatingImages}>
                    עדכן תמונות
                </LoadingButton>
            </Grid>
        </Grid>
    );
});

export default BrandImagesPickers;
