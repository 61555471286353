import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import EventPageStore, { EventType } from './store';
import { observer } from 'mobx-react';
import { Box, CircularProgress, Grid, LinearProgress, MenuItem, Select, Typography } from '@mui/material';
import { getEventPage } from '../../api';
import ImagesUploader from '../../components/ImagesUploader';
import ExistingImagesSection from './ExistingImagesSection';
import AnalyticsSection from './AnalyticsSetion';
import EventLinksSection from './EventLinksSection';
import ActionButtonsSection from './ActionButtonsSection';
import DeleteEventModal from './DeleteEventModal';
import ScanEventImagesModal from './ActiveEventPage/ScanEventImagesModal';
import StartEventModal from './NonActiveEventPage/StartEventModal';
import PracticipentsFilePicker from './NonActiveEventPage/PracticipentsFilePicker';
import { EventMode } from '../../models/IEvent';
import BrandImagesPickers from './BrandImagesPickers';
import { IEventPage } from '../../models/IEventsPage';
import CopyLinkCard from '../../components/CopyLinkCard';

const EventPage: React.FC<{}> = observer(({ }) => {
    const { eventId } = useParams();

    if (!eventId) throw new Error("Event id is missing");
    const { data: eventPage, error, isLoading, } = useQuery(['eventPage', eventId], () => getEventPage(eventId), { refetchOnWindowFocus: false, });
    const eventPageData = eventPage as IEventPage;

    useEffect(() => {
        EventPageStore.setFewImagesFromEvent(eventPageData?.fewImagesFromTheEvent || []);
        EventPageStore.setImagesCount(eventPageData?.imagesCount || 0);
    }, [eventPage])

    useEffect(() => {
        EventPageStore.setEventId(eventId);
    }, [eventId]);

    useEffect(() => {
        return () => {
            EventPageStore.clear();
        }
    }, []);

    useEffect(() => {
        EventPageStore.setIsEventActive(eventPageData?.isActive ?? false);
        EventPageStore.setEventName(eventPageData?.name ?? '');
    }, [eventPage]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>משהו השתבש אנא נסה שנית מאוחר יותר...</div>;
    }

    const event = eventPage as IEventPage & { imagesLimit: number };
    const uploadedImages = EventPageStore.imagesCount;
    const imagesLimit = event?.imagesLimit;
    const uploadProgress = Math.round((uploadedImages / imagesLimit) * 100);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw' // ensures the Box takes full width of the viewport
        }}>
            <Box sx={{ maxWidth: '800px', width: '100%', textAlign: 'start' }}>
                <Typography variant="h4" fontWeight="bold" mb={1}>
                    האירוע של {event.name}
                </Typography>
                {
                    event.isActive &&
                    <Typography variant="body1" color="grey" gutterBottom>
                        האירוע מתקיים ברגעים אלו...
                    </Typography>
                }

                <LinearProgress variant="determinate" value={uploadProgress} sx={{ width: '100%', height: '10px', marginBottom: '5px', borderRadius: "16px" }} />
                <Typography variant="body2" color="grey" textAlign="start" gutterBottom>
                    {uploadedImages}/{imagesLimit} תמונות הועלו
                </Typography>
                <AnalyticsSection
                    activePracticipantsCount={event.activePracticipantsCount}
                    allPracticipantsCount={event.allPracticipantsCount}
                    uploadedImagesCount={event.imagesCount} />
                <Box mt={3}>
                    <ImagesUploader />
                </Box>
                {
                    !EventPageStore.isEventActive &&
                    <>
                        <Box mt={2}>
                            <Typography variant="body2" color="grey" textAlign="start" gutterBottom> סוג אירוע: </Typography>
                            <Select
                                dir="rtl"
                                defaultValue="wedding"
                                sx={{ width: "100%", '.MuiOutlinedInput-notchedOutline': { borderRadius: '16px' } }}
                                onChange={(e) => EventPageStore.setEventType(e.target.value as EventType)}
                            >
                                <MenuItem value="wedding" dir="rtl">חתונה</MenuItem>
                                <MenuItem value="hina" dir="rtl">חינה</MenuItem>
                                <MenuItem value="conference" dir="rtl">כנס</MenuItem>
                                <MenuItem value="party" dir="rtl">מסיבה</MenuItem>
                            </Select>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="body2" color="grey" textAlign="start" gutterBottom> שיטת הפצה: </Typography>
                            <Select
                                dir="rtl"
                                defaultValue="whatsapp"
                                sx={{ width: "100%", '.MuiOutlinedInput-notchedOutline': { borderRadius: '16px' } }}
                                onChange={(e) => EventPageStore.setEventMode(e.target.value as EventMode)}
                            >
                                <MenuItem value="whatsapp" dir="rtl">וואצאפ בלבד</MenuItem>
                                <MenuItem value="hybrid" dir="rtl">וואצאפ + מסך האלבום שלי</MenuItem>
                                <MenuItem value="messagesClientUi" dir="rtl">web mode</MenuItem>
                            </Select>
                        </Box>
                    </>
                }
                <Box>
                    {!EventPageStore.isEventActive && <PracticipentsFilePicker />}
                </Box>
                <BrandImagesPickers backgroundImageUrl={eventPageData.backgroundImageUrl} mainImageUrl={eventPageData.mainPageUrl} />
                <Box mt={5}>
                    <ExistingImagesSection images={EventPageStore.fewImagesFromEvent || []} />
                </Box>
                <Box>
                    <CopyLinkCard
                        link={`
קישור להעלאת תמונות : ${event.photographerUploadLink}
קוד גישה להעלאת תמונות : ${event.photographerUploadLinkCode}
`}
                        label="העתק קישור העלאת תמונות לאירוע" />
                    <EventLinksSection joinEventLinkUrl={event.joinEventLink} />
                    <ActionButtonsSection />
                </Box>
            </Box>
            {EventPageStore.deleteEventModalOpen && <DeleteEventModal />}
            {EventPageStore.scanEventModalOpen && <ScanEventImagesModal />}
            {EventPageStore.startEventModalOpen && < StartEventModal />}
        </Box>
    );
});

export default EventPage;
