import { Box, Divider, Grid, Typography } from "@mui/material";
import memoraLogoWhite from "../../../assets/images/memora-logo-whita-a.png";
import instagramWhiteButton from "../../../assets/images/instagram_white_button.png";
import whatsappWhiteButton from "../../../assets/images/whatsapp_white_button.png";

const BottomFooter: React.FC<{}> = () => {
    return (
        <Box
            component="footer"
            sx={{
                background: "rgba(135, 69, 243, 1)",
                width: '100%',
                mt: 'auto',
                padding: "13px",
            }}
        >
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} textAlign="center">
                    <img
                        src={memoraLogoWhite}
                        alt="footer"
                        style={{ width: '150px', height: 'auto', margin: '0 auto' }}
                    />
                </Grid>
                <Divider
                    sx={{
                        height: "0.5px",
                        background: "white",
                        width: "90%",
                        mx: "auto",
                        mt: 2,
                        mb: 1,
                    }}
                />
                <Typography variant="h6" color="white" textAlign="center" sx={{ mb: 2 }}>
                    service@memora-ai.cloud
                </Typography>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <img
                            onClick={() => window.open("https://wa.me/972515170242")}
                            src={whatsappWhiteButton}
                            alt="WhatsApp"
                            style={{ width: "35px", height: "35px", cursor: "pointer" }}
                        />
                    </Grid>
                    <Grid item>
                        <img
                            src={instagramWhiteButton}
                            onClick={() => window.open("https://www.instagram.com/memora.ai/")}
                            alt="Instagram"
                            style={{ width: "35px", height: "35px", cursor: "pointer" }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default BottomFooter;
