import { useQuery } from "react-query";
import { getMyAlbumPageBySharedLink } from "../../../api";
import { IMyEventAlbumPage } from "../MyAlbumPageByCode";

export function useFetchAlbumBySharedLink(eventId: string, sharedLinkId: string) {

    const { data, error, isLoading, refetch } = useQuery<IMyEventAlbumPage>(
        ["myAlbumPageBySharedLink"],
        () => getMyAlbumPageBySharedLink(eventId, sharedLinkId),
        {
            enabled: true,
            retry: false,
        }
    );

    return { refetch, data, error, isLoading };
}