import { makeAutoObservable } from "mobx";
import { IPracticipant } from "../../models/IPracticipant";
import { EventMode } from "../../models/IEvent";

export type EventType = "wedding" | "conference" | "hina" | "party";
export class EventPageStore {
    public practicipents: IPracticipant[];
    public startEventModalOpen: boolean;
    public deleteEventModalOpen: boolean;
    public scanEventModalOpen: boolean;
    public eventId: string;
    public isEventActive: boolean;
    public eventType: EventType;
    public eventName: string;
    public eventDate: string;
    public fewImagesFromEvent: string[];
    public isLaunchingEvent: boolean;
    public imagesCount: number;
    public eventMode: EventMode;
    public backgroundImage: File | null;
    public mainImage: File | null;

    public constructor() {
        this.practicipents = [];
        this.startEventModalOpen = false;
        this.deleteEventModalOpen = false;
        this.scanEventModalOpen = false;
        this.eventId = '';
        this.eventDate = '';
        this.isEventActive = false;
        this.eventType = "wedding";
        this.eventName = "";
        this.fewImagesFromEvent = [];
        this.isLaunchingEvent = false;
        this.imagesCount = 0;
        this.eventMode = "whatsapp";
        this.backgroundImage = null;
        this.mainImage = null;

        makeAutoObservable(this);
    }

    public clear(): void {
        this.practicipents = [];
        this.startEventModalOpen = false;
        this.scanEventModalOpen = false;
        this.deleteEventModalOpen = false;
        this.eventId = '';
        this.isEventActive = false;
    }

    public setMainImage(mainImage: File | null): void {
        this.mainImage = mainImage;
    }

    public setBackgroundImage(backgroundImage: File | null): void {
        this.backgroundImage = backgroundImage;
    }

    public setEventDate(eventDate: string): void {
        this.eventDate = eventDate;
    }

    public setImagesCount(imagesCount: number): void {
        this.imagesCount = imagesCount;
    }

    public setIsLaunchingEvent(isLaunchingEvent: boolean): void {
        this.isLaunchingEvent = isLaunchingEvent;
    }

    public setFewImagesFromEvent(fewImagesFromEvent: string[]): void {
        this.fewImagesFromEvent = fewImagesFromEvent;
    }

    public setEventName(eventName: string): void {
        this.eventName = eventName;
    }

    public setEventMode(eventMode: EventMode): void {
        this.eventMode = eventMode;
    }

    public setEventType(eventType: EventType): void {
        this.eventType = eventType;
    }

    public setIsEventActive(isEventActive: boolean): void {
        this.isEventActive = isEventActive;
    }

    public setEventId(eventId: string): void {
        this.eventId = eventId;
    }

    public setPracticipents(practicipents: IPracticipant[]): void {
        this.practicipents = practicipents;
    }

    public setScanEventModalOpen(scanEventModalOpen: boolean): void {
        this.scanEventModalOpen = scanEventModalOpen;
    }

    public setStartEventModalOpen(startEventModalOpen: boolean): void {
        this.startEventModalOpen = startEventModalOpen;
    }

    public setDeleteEventModalOpen(deleteEventModalOpen: boolean): void {
        this.deleteEventModalOpen = deleteEventModalOpen;
    }

}

export default new EventPageStore();