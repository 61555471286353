import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { LinearProgress, Button, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getEventImagesCount, getFewImagesFromEvent, getMultipleUploadPresignedUrls, getMultipleUploadPresignedUrlsPhotographers, scanEvent } from '../api';
import EventPageStore from '../pages/EventPage/store';
import AddImages from "../assets/images/add_images.png";

const maxConcurrentUploads = 3; // Reduced concurrency to limit resource usage
const uploadDelay = 500; // Introduce a delay of 500ms between uploads
const batchSize = 50; // Process files in batches of 50
const batchDelay = 2000; // Delay of 2 seconds between each batch
const scanDelay = 5000; // 5 seconds delay before calling scanEvent

interface ImagesUploaderProps {
    width?: string;
    isPhotograper?: boolean;
    pinCode?: string;
}

const ImagesUploader: React.FC<ImagesUploaderProps> = ({ width = "100%", isPhotograper, pinCode }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedCount, setUploadedCount] = useState(0); // Track progress
    const { eventId } = useParams();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFiles(currentFiles => [...currentFiles, ...acceptedFiles]);
    }, []);

    const uploadFileToS3 = async (file: File, presignedUrl: string) => {
        try {
            await axios.put(presignedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
            });
        } catch (error) {
            toast.error(`העלאה של הקובץ נכשלה: ${file.name}`);
            throw error;
        }
    };

    const getPresignedUrls = async (batchFiles: File[]): Promise<{ fileName: string, presignedUrl: string }[]> => {
        const filesData = batchFiles.map(file => ({
            fileName: file.name,
            contentType: file.type,
        }));
        try {
            const presignedUrls = await isPhotograper ? getMultipleUploadPresignedUrlsPhotographers(eventId!, filesData, pinCode!) : getMultipleUploadPresignedUrls(eventId!, filesData);
            return presignedUrls; // Returns an array of { fileName, presignedUrl }
        } catch (error) {
            toast.error('Failed to retrieve presigned URLs');
            throw error;
        }
    };

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const uploadBatch = async (batchFiles: File[]) => {
        const presignedUrls = await getPresignedUrls(batchFiles);

        const concurrentUploads: Promise<void>[] = [];
        let activeUploads = 0;

        for (const file of batchFiles) {
            const filePresignedUrl = presignedUrls.find(urlObj => urlObj.fileName === file.name);
            if (!filePresignedUrl) {
                continue; // Skip this file if no presigned URL is found
            }

            const uploadTask = uploadFileToS3(file, filePresignedUrl.presignedUrl)
                .then(() => {
                    setUploadedCount((prevCount) => prevCount + 1);
                })
                .catch((error) => {
                    console.error(`Failed to upload file ${file.name}:`, error);
                    toast.error(`העלאה של הקובץ נכשלה: ${file.name}`);
                })
                .finally(() => {
                    activeUploads--;
                });

            concurrentUploads.push(uploadTask);
            activeUploads++;

            if (activeUploads >= maxConcurrentUploads) {
                await Promise.race(concurrentUploads);
            }

            await delay(uploadDelay);
        }

        await Promise.all(concurrentUploads);
    };


    const uploadFiles = async () => {
        if (files.length === 0) {
            toast.error("לא נבחרו קבצים להעלאה...");
            return;
        }

        setIsUploading(true);
        setUploadedCount(0);

        try {
            for (let i = 0; i < files.length; i += batchSize) {
                const batchFiles = files.slice(i, i + batchSize);

                await uploadBatch(batchFiles);

                if (i + batchSize < files.length) {
                    await delay(batchDelay);
                }
            }

            if (isPhotograper && pinCode) {
                await delay(scanDelay);
                await scanEvent(eventId!, pinCode);
            }

            if (EventPageStore.fewImagesFromEvent?.length === 0) {
                const images = await getFewImagesFromEvent(eventId!);
                EventPageStore.setFewImagesFromEvent(images);
            }
            const imagesCount = await getEventImagesCount(eventId!);
            EventPageStore.setImagesCount(imagesCount);

            toast.success('כל התמונות הועלו בהצלחה והופצו בהצלחה !', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: 'light',
            });
        } catch (error) {
            console.error('An error occurred during the upload process:', error);
            toast.error('An error occurred during the upload.');
        } finally {
            setFiles([]);
            setIsUploading(false);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
        },
    });

    const uploadProgress = files.length > 0 ? (uploadedCount / files.length) * 100 : 0;

    return (
        <Box sx={{ width: width }}>
            <Typography textAlign="left" variant="h5" fontWeight="600">
                העלה תמונות
            </Typography>
            <Box
                {...getRootProps()}
                sx={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: 250,
                    border: '2px dashed #d9d9d9',
                    borderRadius: '16px',
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    },
                    backgroundColor: isDragActive ? '#aaa' : '#fff',
                }}
            >
                <input {...getInputProps()} id="image-upload" style={{ display: 'none' }} />
                <img src={AddImages} alt="Upload" style={{ width: 100, height: 100 }} />
                <Typography variant="h6" fontWeight="bold">גרור ושחרר תמונות לכאן</Typography>
                <Typography variant="subtitle1" fontWeight="600" mt={1}>
                    נבחרו {files.length} תמונות
                </Typography>
            </Box>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Button
                style={{
                    marginTop: "10px",
                    width: "100%",
                    borderRadius: "12px",
                    background: 'linear-gradient(157.4deg, #FF77C0 7.03%, #8745F3 48.3%, #70D4FF 95.95%)',
                    color: 'white',
                    padding: "5px 10px",
                    border: 'none',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textTransform: 'none',
                    boxShadow: 'none',
                }}
                onClick={uploadFiles}
                disabled={isUploading}
            >
                העלה תמונות
            </Button>

        </Box>
    );
};

export default ImagesUploader;
