import axios, { AxiosError } from "axios";
import { IEvent } from "./models/IEvent";
import { IUser } from "./models/IUser";
import Cookies from "js-cookie";
import { IEventPage } from "./models/IEventsPage";
import { IPinCodeScreen } from "./models/IPinCodeScreen";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
    config => {
        const token = JSON.parse(localStorage.getItem('user') as string)?.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

api.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            logoutUser();
        }
        return Promise.reject(error);
    }
);

// auth.js
export const logoutUser = () => {
    localStorage.removeItem('user');
    window.location.href = '/login';
};


const getUser = (): IUser => {
    return JSON.parse(localStorage.getItem('user') as string);
};

export const getEventById = async (eventId: string) => {
    try {
        const response = await api.get(`/event/getEventById/${eventId}`);
        return response.data.event;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getEventNameById = async (eventId: string) => {
    try {
        const response = await api.get(`/event/getEventNameById/${eventId}`);
        return response.data.eventName;
    } catch (error) {
        return handleApiError(error);
    }
};

export const scanEventImages = async (eventId: string) => {
    try {
        await api.post('/event/scanEvent', { eventId });
    } catch (error) {
        return handleApiError(error);
    }
};

export const deleteEvent = async (eventId: string) => {
    try {
        await api.post('/event/deleteEvent', { eventId });
    } catch (error) {
        return handleApiError(error);
    }
};

export const scanEvent = async (eventId: string, pinCode?: string) => {
    const endPoint = pinCode ? '/scanEvent' : '/event/scanEvent';
    try {
        await api.post(endPoint, { eventId, pinCode });
    } catch (error) {
        return handleApiError(error);
    }
};

export const startEvent = async (event: Partial<IEvent>, files?: { mainImage: File | null, backgroundImage: File | null }) => {
    try {
        const formData = new FormData();

        // Rename and append files to the FormData object
        if (files?.backgroundImage) {
            const renamedBackgroundImage = new File([files.backgroundImage], "background-image.jpg", {
                type: files.backgroundImage.type,
            });
            formData.append("files", renamedBackgroundImage);
        }
        if (files?.mainImage) {
            const renamedMainImage = new File([files.mainImage], "main-image.jpg", {
                type: files.mainImage.type,
            });
            formData.append("files", renamedMainImage);
        }
        // Append other data to the FormData object
        formData.append("eventId", event.id as string);
        formData.append("mode", event.mode as string);
        formData.append("eventType", event.eventType as string);

        // Convert participants array to a JSON string and append it
        formData.append("practicipentsData", JSON.stringify(event.practicipants));

        const response = await api.post('/event/startEvent', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response;
    } catch (error) {
        return handleApiError(error);
    }
};


export const fetchEvents = async () => {
    try {
        const userId = getUser().userId;
        const response = await api.get(`/event/getEvents/${userId}`);
        return response.data.events;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getEventsPage = async () => {
    try {
        const userId = getUser().userId;
        const response = await api.get(`/event/getEventsPage/${userId}`);
        return response.data.eventsPage;
    } catch (error) {
        return handleApiError(error);
    }
};

export const createEvent = async (eventName: string, eventDate: string, packageName: string) => {
    try {
        const userId = getUser().userId;
        const response = await api.post('/event/createEvent', { name: eventName, userId: userId, packageName, eventDate });
        return response.data.eventId;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getPresignedUrl = async (eventId: string, file: File) => {
    try {
        const response = await api.post('/s3/createPresignedUrl', {
            fileName: `${eventId}/${file.name}`,
            contentType: file.type,
        });
        return response.data.url;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getFewImagesFromEvent = async (eventId: string) => {
    try {
        const response = await api.get(`/s3/getFewImagesFromEvent/${eventId}`);
        return response.data.fewImagesFromTheEvent;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getEventPage = async (eventId: string) => {
    try {
        const response = await api.get(`/event/getEventPage/${eventId}`);
        return response.data as IEventPage;
    } catch (error) {
        return handleApiError(error);
    }
};

export const uploadImagesToEvent = async (eventId: string, files: File[]) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    try {
        const response = await api.post(`/event/uploadImagesToEvent/${eventId}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response;
    } catch (error) {
        return handleApiError(error);
    }
};

export const getEventImagesCount = async (eventId: string) => {
    try {
        const response = await api.get(`/event/getEventImagesCount/${eventId}`);
        return response.data.imagesCount;
    } catch (error) {
        console.error('Failed to get event images count:', error);
    }
};

export const login = async (email: string, password: string) => {
    try {
        const response = await api.post('/login', { email, password });
        return response;
    } catch (error) {
        console.error('Failed to login:', error);
    }
};

export const signup = async (email: string, password: string, phone: string, firstName: string, fammilyName: string) => {
    try {
        const response = await api.post('/signup', { email, password, firstName, fammilyName, phone });
        return response;
    } catch (error: any) {
        return error.response;
    }
};

export const isTokenValid = async (token: string) => {
    try {
        const response = await api.get(`/validateToken/${token}`);
        return response.status === 200;
    } catch (error) {
        console.error('Failed to check token:', error);
    }
};

export const resetPassword = async (email: string) => {
    const response = await api.post('/resetPassword', { email });
    return response;
};

export const getUserPackages = async () => {
    try {
        const userId = getUser().userId;
        const response = await api.get(`/getUserPackages/${userId}`);
        return response.data.packages;
    } catch (error) {
        console.error('Failed to get user packages:', error);
    }
};

export const getMyAlbumPageByCode = async (eventId: string, pinCode: string, phoneNumber: string) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/getAlbumByCode/${eventId}/${phoneNumber}`, { pinCode });
        const eventsPinCode = JSON.parse(Cookies.get('eventsPinCode') || "{}") as unknown as Record<string, string> || {};
        eventsPinCode[eventId] = pinCode;

        Cookies.set('eventsPinCode', JSON.stringify(eventsPinCode), { expires: 7 });

        return response.data;
    } catch (error: any) {
        console.error('Failed to get my images page:', error);
        throw error;
    }
};

export const getMyAlbumPageBySelfie = async (eventId: string, selfieImage: File, phoneNumber?: string) => {
    try {
        const formData = new FormData();
        formData.append("selfie", selfieImage); // Append the selfie before making the request

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/getAlbumBySelfie/${eventId}/${phoneNumber}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    } catch (error: any) {
        console.error('Failed to get my images page:', error);
        throw error;
    }
};

export const getMyAlbumPageBySharedLink = async (eventId: string, sharedLinkId: string) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getMyAlbumBySharedLink/${eventId}/${sharedLinkId}`);

        return response.data;
    } catch (error: any) {
        console.error('Failed to get my images page:', error);
        throw error;
    }
};

export const getSharedAlbumLink = async (eventId: string, imagesToDownLoad: string[]) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/getSharedAlbumLink/${eventId}`, { imagesToDownLoad });

        return response.data.link;
    } catch (error: any) {
        console.error('Failed to get my images page:', error);
        throw error;
    }
};

export const updateEventBrandingImages = async (eventId: string, mainImage: File | null, backgroundImage: File | null) => {
    const formData = new FormData();

    // Rename and append files to the FormData object
    if (backgroundImage) {
        const renamedBackgroundImage = new File([backgroundImage], "background-image.jpg", {
            type: backgroundImage.type,
        });
        formData.append("files", renamedBackgroundImage);
    }
    if (mainImage) {
        const renamedMainImage = new File([mainImage], "main-image.jpg", {
            type: mainImage.type,
        });
        formData.append("files", renamedMainImage);
    }

    try {
        const response = await api.post(`/event/updateEventBrandingImages/${eventId}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response;
    } catch (error) {
        return handleApiError(error);
    }
}

export const downloadEventImagesAlbum = async (
    eventId: string,
    imagesToDownLoad: string[] = []
) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/download-album/${eventId}`,
            { imagesToDownLoad },
            {
                responseType: 'blob',
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `album-${eventId}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error: any) {
        console.error('Failed to download event images album:', error);
        throw error;
    }
};

export const getPinCodePage = async (eventId: string, pinCode: string, phoneNumber: string) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/getPinCodePage/${eventId}/${phoneNumber}`, { pinCode });
        return response.data as IPinCodeScreen;
    } catch (error) {
        console.error('Failed to get pin code screen:', error);
    }
};

export const getImagesData = async (eventId: string, imagesKeys: string[]) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/getImagesData/${eventId}`, { imagesKeys });

        return response.data.imagesData;
    } catch (error) {
        console.error('Failed to get images data:', error);
    }
}

export const getSelfiePage = async (eventId: string) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getSelfieScreen/${eventId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to get selfie page:', error);
    }
}

export const getMultipleUploadPresignedUrls = async (eventId: string, files: { contentType: string, fileName: string }[]) => {
    try {
        const response = await api.post(`${process.env.REACT_APP_API_URL}/event/getMultipleUploadPresignedUrls`, { eventId, files });

        return response.data.presignedUrls;
    } catch (error) {
        console.error('Failed to get images data:', error);
    }
}

export const getMultipleUploadPresignedUrlsPhotographers = async (eventId: string, files: { contentType: string, fileName: string }[], pinCode: string) => {
    try {
        const response = await api.post(`${process.env.REACT_APP_API_URL}/getMultipleUploadPresignedUrls`, { eventId, files, pinCode });

        return response.data.presignedUrls;
    } catch (error) {
        console.error('Failed to get images data:', error);
    }
}

export const getImagesUploadPage = async (eventId: string, pinCode: string) => {
    try {
        const response = await api.post(`${process.env.REACT_APP_API_URL}/getImagesUploadPage`, { eventId, pinCode });

        return response.data;
    } catch (error) {
        console.error('Failed to get images data:', error);
    }
}

export const getSendMyImagesToWhatsappLink = async (eventId: string, eventData: { selfieImage?: File, userPhoneNumber?: string, sharedEventLinkId?: string }) => {
    try {
        const formData = new FormData();
        if (eventData.selfieImage) {
            formData.append("selfie", eventData.selfieImage); // Append the selfie before making the request
        }

        if (eventData.userPhoneNumber) {
            formData.append("userPhoneNumber", eventData.userPhoneNumber);
        }

        if (eventData.sharedEventLinkId) {
            formData.append("sharedEventLinkId", eventData.sharedEventLinkId);
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/sendMyImagesToWhatsapp/${eventId}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data.getImagesWhatsappLink;
    } catch (error) {
        console.error('Failed to get images data:', error);
    }
}


// Helper function to handle API errors
const handleApiError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
        return error.response;
    } else {
        throw new Error('An unexpected error occurred');
    }
};