import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { observer } from "mobx-react";
import defaultBackgroundImage from "../../assets/images/default_background_image.png";
import MyAlbumPageStore from "./store";
import Loader from "../../components/Loader";
import AlbumPageUI, { whatsappLinkCache } from "./components/AlbumPageUI";
import { useFetchAlbumBySelfie } from "./hooks/useFetchAlbumBySelfie";
import ErrorPage from "../../components/404Page";
import { getSendMyImagesToWhatsappLink } from "../../api";

export type AlbumImage = {
    displayUrl: string;
    downloadUrl: string;
    key: string;
};

export interface IMyEventAlbumPage {
    images: AlbumImage[];
    eventName: string;
    backgroundImageUrl: string;
    mainImageUrl: string;
    eventDate: string;
}

// Helper function to convert base64 to a File object
const base64ToFile = (base64: string, filename: string): File => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)?.[1] || "";
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};


const MyAlbumPageBySelfie: React.FC<{}> = observer(() => {
    const { eventId, phoneNumber } = useParams();
    const navigate = useNavigate();
    if (!eventId) throw new Error("משהו השתבש אנא נסה שנית מאוחר יותר");

    // Try to get the selfie image from the cookie or the store
    const savedSelfie = localStorage.getItem("selfie");

    useEffect(() => {
        if (!savedSelfie && !MyAlbumPageStore.selfieImage) {
            navigate(`/selfie/${eventId}`);
        }
    }, [])

    const selfieImage = savedSelfie ? base64ToFile(savedSelfie, "selfie.png") : MyAlbumPageStore.selfieImage;
    const { data, isLoading, error } = useFetchAlbumBySelfie(eventId, selfieImage!, phoneNumber);

    useEffect(() => {
        MyAlbumPageStore.setImages(data?.images.filter(Boolean) || []);
    }, [data]);

    const handleShareAlbum = () => {
        MyAlbumPageStore.handleShareAlbum(eventId, data?.eventName || "");
    };

    const handleGlobalDownload = async () => {
        MyAlbumPageStore.handleGlobalDownload(eventId);
    };

    const handleOnSendToWhatsApp = async () => {
        if (!selfieImage || !eventId) return;

        // Check if the link is already cached
        if (whatsappLinkCache[eventId]) {
            // Open the cached link if available
            window.open(whatsappLinkCache[eventId], "_blank");
            return;
        }

        try {
            // Fetch the WhatsApp link and cache it
            const whatsappLink = await getSendMyImagesToWhatsappLink(eventId, { selfieImage });
            whatsappLinkCache[eventId] = whatsappLink;  // Cache the link

            window.open(whatsappLink, "_blank");
        } catch (err) {
            console.error(err);
        }
    };

    if (error) {
        return (
            <ErrorPage />
        );
    }

    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        <AlbumPageUI
            backgroundImageUrl={data?.backgroundImageUrl || defaultBackgroundImage}
            mainImageUrl={data?.mainImageUrl || ""}
            eventName={data?.eventName || ""}
            eventDate={data?.eventDate || ""}
            images={MyAlbumPageStore.images}
            onGlobalDownload={handleGlobalDownload}
            onShareAlbum={handleShareAlbum}
            isLoading={isLoading}
            onSendToWhatsApp={handleOnSendToWhatsApp}
        />
    );
});

export default MyAlbumPageBySelfie;
